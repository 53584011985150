import { PersonaService } from "../services/PersonaService";
import { AuthHelper } from '../auth/AuthHelper';
import { useMsal } from "@azure/msal-react";
import { IChatPersona } from "../models/ChatPersona";

export const usePersonas = () => {
    const personaService = new PersonaService();
    const { instance, inProgress } = useMsal();

    const getAllPersonas = async () => {
        let personas: IChatPersona[] = [];
        try {
            personas = await personaService.getAllPersonasAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            console.error(error);
        }
        return personas;
    }

    const getPersonasByUser = async () => {
        let personas : IChatPersona[] = [];
        try {
            personas = await personaService.getAllPersonasByUserAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        }
        catch (error) {
            // TODO: Add error handling
            console.error(error);
        }
        return personas;
    }

    const getPersonaById = async (personaId?: string) => {
        let persona : IChatPersona = {} as IChatPersona;
        persona.icon = 'bot-icon-1';
        persona.id = "00000000-0000-0000-0000-000000000000";
        persona.name = "Litium";

        if (!personaId) {
            return persona;
        }

        try {
            persona = await personaService.getChatPersonaAsync(personaId, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        }
        catch (error) {
            // TODO: Add error handling
            console.error(error);
            return persona;
        }
        return persona;
    }

    const createPersona = async (persona: IChatPersona) => {
        try {
            return await personaService.createChatPersonaAsync(persona, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            // TODO: Add error handling
            console.error(error);
            return persona;
        }
    }

    const updatePersona = async (persona: IChatPersona) => {
        try {
            return await personaService.editChatPersonaAsync(persona, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            // TODO: Add error handling
            console.error(error);
            return persona;
        }
    }

    const deletePersona = async (personaId: string) => {
        try {
            await personaService.deleteChatPersonaAsync(personaId, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            // TODO: Add error handling
            console.error(error);
        }
    }

    const isUserPersonaAdmin = async () => {
        let isAdmin = false;
        try {
            isAdmin = await personaService.isUserPersonaAdminAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            // TODO: Add error handling
            console.error(error);
        } finally {
            return isAdmin;
        }
    }

    return {
        getAllPersonas,
        getPersonasByUser,
        getPersonaById,
        createPersona,
        updatePersona,
        deletePersona,
        isUserPersonaAdmin,
    }

}
