/* eslint-disable */
import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";
import { useChat } from '../../../libs/hooks';
import { useEffect, useState } from "react";
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
//import { setSelectedDocumentView } from '../../../redux/features/app/appSlice';



export interface LitiumDocViewerProps {
    chatId?: string;
    filename?: string;
    documentId?: string;
    mimeType?: string;
    page?: number;
}

export const LitiumDocViewer : React.FC<LitiumDocViewerProps> =() => {
  const [docs, setDocs] = useState<IDocument[]>([]);
  const { selectedDocument } = useAppSelector((state: RootState) => state.app);

  let chatId: string;
  let filename: string;
  let documentId: string;
  let mimeType: string;
  //let page: number;

    const chat = useChat();

    useEffect(() => {

      chatId = selectedDocument.chatId;
      filename = selectedDocument.filename;
      documentId = selectedDocument.documentId;
      mimeType = selectedDocument.mimeType;
      //page = selectedDocument.page;
  
      const cid:string = chatId;
      const did:string = documentId;

      if(documentId==''){
        return;
      }

      chat.getDocumentBytes(cid, did).then(
        (result)=>{
           if(result!=undefined && mimeType!=undefined){
            const fileBlobUrl = dataToBlob(result, mimeType);
            const newDocs:IDocument[] = [{uri : fileBlobUrl, fileName : filename}];
            setDocs(newDocs);
          }
        });
      
    }, [selectedDocument]);
    
    function dataToBlob(inputBytes:string, mimeType:string) {
      var byteString = atob(inputBytes);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
      var bb = new Blob([ab], {type: mimeType});
      const blobUrl = URL.createObjectURL(bb);
      return blobUrl;
    }
    

    return (
        <div style={{margin:"0", padding:"0", backgroundColor:"#f5f5f5"}} >
        <div style={{height:"60px", margin:"0", padding:"0", backgroundColor:"var(--colorNeutralBackground4)", top:"0", borderBottom:"1.25px solid #0000001a"}}>&nbsp;</div>
        <div style={{backgroundColor:"var(--colorNeutralBackground4) !important",borderLeft:"1.25px solid #0000001a"}} >
        <DocViewer documents={docs}
            pluginRenderers={DocViewerRenderers} 
            config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false,
                },
                csvDelimiter: ",", // "," as default,
                pdfZoom: {
                  defaultZoom: 1, // 1 as default,
                  zoomJump: 0.2, // 0.1 as default,
                },
                pdfVerticalScrollByDefault: true, // false as default
              }}
              style={{width:'500px', height:"80%"}}
        />
        </div>
        </div>
    );
};