import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Tooltip } from "@fluentui/react-components";
import { FC } from "react";
import { Filter20 } from "../../shared/BundledIcons";
import { ChatListSortOptions } from "../../../redux/features/app/AppState";
import { useDispatch } from "react-redux";
import { setChatListSortOption } from "../../../redux/features/app/appSlice";

export const SortChatMenu: FC = () => {
    const dispatch = useDispatch();
    const onSortChatList = (sortOption: ChatListSortOptions) => {
        dispatch(setChatListSortOption(sortOption));
    }

    return (
        <div>
            <Menu>
                <MenuTrigger>
                    <Tooltip content="Sort Chat List" relationship="label">
                        <Button data-testid="sortChatListButton" icon={<Filter20 />} appearance="transparent" />
                    </Tooltip>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem data-testid="sortChatListByLastActivity" onClick={() => { onSortChatList(ChatListSortOptions.Date) }}>
                            Last Activity
                        </MenuItem>
                        <MenuItem data-testid="sortChatListByPersona" onClick={() => { onSortChatList(ChatListSortOptions.Persona) }}>
                            Chat Persona
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </div>
    )
}
