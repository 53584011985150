import { useEffect } from 'react';
// @ts-expect-error No type declaration
import reactManifest from 'react-manifest';



export const PwaManifest = () => {

    useEffect(() => {
        
        //var abc = process.env.REACT_APP_BACKEND_URI == null || process.env.REACT_APP_BACKEND_URI.trim() === '' ? window.origin : process.env.REACT_APP_BACKEND_URI;
        
        const shortName = process.env.REACT_APP_PWA_SHORTNAME == null || process.env.REACT_APP_PWA_SHORTNAME.trim() === '' ? "TW Litium" : process.env.REACT_APP_PWA_SHORTNAME;
        const Name = process.env.REACT_APP_PWA_NAME == null || process.env.REACT_APP_PWA_NAME.trim() === '' ? "TW Litium" : process.env.REACT_APP_PWA_NAME;
        const Description = process.env.REACT_APP_PWA_DESCRIPTION == null || process.env.REACT_APP_PWA_DESCRIPTION.trim() === '' ? "TW Litium is an AI chatbot which can assist with a range of tasks" : process.env.REACT_APP_PWA_DESCRIPTION;
        const BgColor = process.env.REACT_APP_PWA_BGCOLOR == null || process.env.REACT_APP_PWA_BGCOLOR.trim() === '' ? "#0f1c44" : process.env.REACT_APP_PWA_BGCOLOR;
        const ThemeColor = process.env.REACT_APP_PWA_THEMECOLOR == null || process.env.REACT_APP_PWA_THEMECOLOR.trim() === '' ? "#0f1c44" : process.env.REACT_APP_PWA_THEMECOLOR;
        const startUrl = window.location.href + "/?source=pwa";

        
        
        const manifestDetails = {
            "short_name": "TW Litium",
            "name": "TW Litium Development",
            "icons": [
              {
                "src": "favicon.ico",
                "type": "image/icons-512.png",
                "sizes": "512x512"
              },
              {
                "src": "/favicon.ico",
                "type": "image/png",
                "sizes": "192x192"
              },
              {
                "src": "/favicon.ico",
                "type": "image/png",
                "sizes": "512x512"
              }
            ],
            "id": "/?source=pwa",
            "start_url": "/?source=pwa",
            "background_color": "#0f1c44",
            "display": "standalone",
            "theme_color": "#0f1c44",
            "description": "TW Litium Development is an AI chatbot which can assist with a range of tasks",
            "screenshots": [
              {
                "src": "/img/screenshot1.png",
                "type": "image/png",
                "sizes": "540x720",
                "form_factor": "narrow"
              },
              {
                "src": "/img/screenshot2.png",
                "type": "image/png",
                "sizes": "720x540",
                "form_factor": "wide"
              }
            ]
          };
          manifestDetails.start_url = startUrl;
          manifestDetails.short_name = shortName;
          manifestDetails.background_color = BgColor;
          manifestDetails.theme_color = ThemeColor;
          manifestDetails.description = Description;
          manifestDetails.name = Name;
          manifestDetails.screenshots.forEach(element => {
            element.src = window.location.href + element.src;
          });

          manifestDetails.icons.forEach(element => {
            element.src = window.location.href + element.src;
          });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        reactManifest.update(manifestDetails, "#manifest-placeholder");
    
    }, []); 

    return (

        <div>
            
        </div>
    
      );
    };