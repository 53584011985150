import { Card, CardHeader, Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { FC } from "react";

const useStyles = makeStyles({
    card: {
        width: "360px",
        maxWidth: "100%",
    },

    caption: {
        color: tokens.colorNeutralForeground3,
    },

    text: {
        ...shorthands.margin(0),
    },
});

interface PromptAdminCardProps {
    id: string;
    title: string;
    description: string;
    onCardClick: (id: string) => void;
}

export const PromptAdminCard: FC<PromptAdminCardProps> = ({id, title, description, onCardClick}) => {
    const styles = useStyles();

    const onClick  = () => {
        onCardClick(id);
    }

    return (
        <Card className={styles.card} onClick={onClick}>
            <CardHeader
                header={<Text weight="semibold">{title}</Text>}
                action={null}
            />
            <p className={styles.text}>
                {description}
            </p>
        </Card>
    )
}
