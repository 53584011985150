import { IChatPersona } from "../models/ChatPersona";
import { BaseService } from "./BaseService";

export class PersonaService extends BaseService {
    public getAllPersonasAsync = async (accessToken: string): Promise<IChatPersona[]> => {
        const result = await this.getResponseAsync<IChatPersona[]>(
            {
                commandPath: 'chatPersonas',
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getAllPersonasByUserAsync = async (accessToken: string): Promise<IChatPersona[]> => {
        const result = await this.getResponseAsync<IChatPersona[]>(
            {
                commandPath: `chatPersonasUser`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getChatPersonaAsync = async (personaId: string, accessToken: string): Promise<IChatPersona> => {
        const result = await this.getResponseAsync<IChatPersona>(
            {
                commandPath: `chatPersona/${personaId}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public createChatPersonaAsync = async (persona: IChatPersona, accessToken: string): Promise<IChatPersona> => {
        const result = await this.getResponseAsync<IChatPersona>(
            {
                commandPath: `chatPersona`,
                method: 'POST',
                body: persona,
            },
            accessToken,
        );
        return result;
    }

    public editChatPersonaAsync = async (persona: IChatPersona, accessToken: string): Promise<IChatPersona> => {
        const result = await this.getResponseAsync<IChatPersona>(
            {
                commandPath: `chatPersona/${persona.id}`,
                method: 'PUT',
                body: persona,
            },
            accessToken,
        );
        return result;
    }

    public deleteChatPersonaAsync = async (personaId: string, accessToken: string): Promise<void> => {
        await this.getResponseAsync<unknown>(
            {
                commandPath: `chatPersona/${personaId}`,
                method: 'DELETE',
            },
            accessToken,
        );
    }

    public isUserPersonaAdminAsync = async (accessToken: string): Promise<boolean> => {
        const result = await this.getResponseAsync<boolean>(
            {
                commandPath: `chatPersona/isAdmin`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }
}
