import { Text, Caption1, Card, CardHeader, shorthands, makeStyles, tokens } from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { setConversationInput, setPrompt } from "../../../redux/features/conversations/conversationsSlice";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";
import { setSelectedChatWindowTab } from "../../../redux/features/app/appSlice";

const useStyles = makeStyles({
    card: {
        width: "360px",
        maxWidth: "100%",
        minHeight: "120px",
        height: "auto",
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
    text: {
        ...shorthands.margin(0),
    },
});

interface PromptCardProps {
    id: string;
    title: string;
    description: string;
    prompt: string;
    persona: string | undefined;
    tags: string[] | undefined;
}

export const PromptCard: React.FC<PromptCardProps> = ({id, title, description, prompt, tags}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const {selectedId} = useAppSelector((state: RootState) => state.conversations);

    const onClick = () => {
        dispatch(setConversationInput({id: selectedId, content: prompt}));
        dispatch(setPrompt({chatId: selectedId, promptId: id, promptName: title}));
        dispatch(setSelectedChatWindowTab('chat'));
    }

    return (
        <Card className={styles.card} onClick={onClick}>
            <CardHeader
                header={<Text weight="semibold">{title}</Text>}
                description={
                    <Caption1 className={styles.caption}>{tags ? tags.join(", ") : ""}</Caption1>
                }
                action={
                    null
                        // <Button
                        //     appearance="transparent"
                        //     icon={<MoreHorizontal20Regular/>}
                        //     aria-label="More options"
                        // />
                }
            />
            <p className={styles.text}>
                {description}
            </p>
        </Card>
    )
}
