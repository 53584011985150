import { AuthHelper } from '../auth/AuthHelper';
import { useMsal } from "@azure/msal-react";
import { PromptService } from "../services/PromptService";
import { IChatPrompt } from '../models/ChatPrompt';
import { ICreateEditChatPrompt } from '../models/CreateEditChatPrompt';

export const usePrompts = () => {
    const promptService = new PromptService();
    const { instance, inProgress } = useMsal();

    const getAllPrompts = async () => {
        let prompts : IChatPrompt[] = [];
        try {
            prompts = await promptService.getAllPromptsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        }
        catch (error) {
            // TODO: Add error handling
            console.error(error);
        }
        return prompts;
    }

    const getPrompt = async (id: string) => {
        let prompt: IChatPrompt | null = null;
        try {
            prompt = await promptService.getPromptAsync(id, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            console.error(error);
        }
        return prompt;
    }

    const createPrompt = async (prompt: ICreateEditChatPrompt) => {
        try {
            await promptService.createPromptAsync(prompt, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            console.error(error);
        }
    }

    const updatePrompt = async (prompt: ICreateEditChatPrompt) => {
        try {
            await promptService.updatePromptAsync(prompt, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            console.error(error);
        }
    }

    const deletePrompt = async (id: string) => {
        try {
            await promptService.deletePromptAsync(id, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            console.error(error);
        }
    }

    const isUserPromptAdmin = async () => {
        let isAdmin = false;
        try {
            isAdmin = await promptService.isUserPromptAdminAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            // TODO: Add error handling
            console.error(error);
        } finally {
            return isAdmin;
        }
    }

    return {
        getAllPrompts,
        getPrompt,
        createPrompt,
        updatePrompt,
        deletePrompt,
        isUserPromptAdmin,
    }
}
