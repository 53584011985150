import { Button, Input, makeStyles, shorthands, tokens } from "@fluentui/react-components"
import { SearchRegular } from "@fluentui/react-icons";
import { usePersonas } from "../../../libs/hooks/usePersonas";
import { useEffect, useState } from "react";
import { IChatPersona } from "../../../libs/models/ChatPersona";
import { PersonaAdminCard } from "./PersonaAdminCard";
import { AddPersonaDialog } from "./AddPersonaDialog";
import { EditPersonaDialog } from "./EditPersonaDialog";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.margin('24px'),
    },
    searchRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    personas: {
        ...shorthands.gap("36px"),
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: "8px",
        marginTop: "24px"
    }
});

export const PersonaAdmin = () => {
    const styles = useStyles();
    const personasHook = usePersonas();
    const [personas, setPersonas] = useState<IChatPersona[]>([]);
    const [isNewPersonaDialogOpen, setIsNewPersonaDialogOpen] = useState(false);
    const [isEditPersonaDialogOpen, setIsEditPersonaDialogOpen] = useState(false);
    const [selectedPersona, setSelectedPersona] = useState(personas[0]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        personasHook.getAllPersonas().then((result) => {
            setPersonas(result);
        }).catch((error) => {
            console.error("Failed to fetch personas:", error);
        });
    }, [refresh]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event);
        // TODO: Implement search
    }

    const onCardClick = (id: string) => {
        const persona = personas.find((persona) => persona.id === id);
        if (!persona) {
            console.error("Failed to find persona with id:", id);
            return;
        }
        setSelectedPersona(persona);
        setIsEditPersonaDialogOpen(true);
    }

    return (
        <div className={styles.root}>
            <div className={styles.searchRow}>
                <Input contentBefore={<SearchRegular/>} onChange={handleSearchChange}/>
                <Button appearance={"primary"} onClick={() => {setIsNewPersonaDialogOpen(true)}}>New Persona</Button>
            </div>
            <div className={styles.personas}>
                {personas.map((persona) => (
                    <PersonaAdminCard key={persona.id} id={persona.id} title={persona.name} description={persona.description} onCardClick={onCardClick}/>
                ))}
            </div>
            <EditPersonaDialog
                open={isEditPersonaDialogOpen}
                onCloseDialog={() => {setIsEditPersonaDialogOpen(false)}}
                persona={selectedPersona}
                onRefresh={() => {setRefresh(!refresh)}}
            />
            <AddPersonaDialog
                open={isNewPersonaDialogOpen}
                onCloseDialog={() => {setIsNewPersonaDialogOpen(false)}}
                onRefresh={() => {setRefresh(!refresh)}}
            />
        </div>
    )
}
