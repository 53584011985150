import React, { useState, useEffect, useMemo } from 'react';
import {
    makeStyles,
    shorthands,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    TableCellLayout,
    Button,
    tokens,
    TableColumnDefinition,
    Spinner,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@fluentui/react-components";
import { ArrowLeftRegular, ChevronLeftRegular, ChevronRightRegular, ArrowUploadRegular } from "@fluentui/react-icons";
import { useWorkflow } from '../../libs/hooks/useWorkflow';
import { WorkflowsFileUploader } from './WorkflowsFileUploader';
import { IUserRequestWorkflowInstanceFiles } from '../../libs/models/Workflows/UserRequestWorkflowInstanceFiles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 150px)',
        ...shorthands.overflow('hidden'),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        ...shorthands.padding(0, '20px'),
    },
    headerButtons: {
        display: 'flex',
        ...shorthands.gap('10px'),
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
    },
    tableContainer: {
        flexGrow: 1,
        overflowY: 'auto',
        ...shorthands.padding('0', '20px'),
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralStroke1,
            ...shorthands.borderRadius('4px'),
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: tokens.colorNeutralStroke1Hover,
        },
    },
    table: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    tableHeader: {
        fontWeight: tokens.fontWeightSemibold,
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
        ...shorthands.padding('20px'),
        ...shorthands.gap('10px'),
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50%',
    },
});

interface ViewDocumentsPageProps {
    workflowInstanceId: string;
    onBack: () => void;
}

interface FileData {
    FileName: string;
    FileLength: number;
    FileDate: string | null;
}

interface WorkflowData {
    payload: string;
}

type SortDirection = 'ascending' | 'descending';

const formatDate = (dateString: string | null) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    return date.toLocaleDateString('en-GB', options);
};

const ITEMS_PER_PAGE = 10;

export const ViewDocumentsPage: React.FC<ViewDocumentsPageProps> = ({ workflowInstanceId, onBack }) => {
    const styles = useStyles();
    const workflowHook = useWorkflow();
    const [documents, setDocuments] = useState<FileData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortColumn, setSortColumn] = useState<keyof FileData>('FileName');
    const [sortDirection, setSortDirection] = useState<SortDirection>('ascending');
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                setIsLoading(true);
                const data = await workflowHook.getWorkflowData(workflowInstanceId, "INBOUND") as WorkflowData;
                if (data.payload) {
                    const parsedPayload = JSON.parse(data.payload) as Array<Record<string, unknown>>;
                    const fileData = parsedPayload.map((item): FileData => ({
                        FileName: item.FileName as string,
                        FileLength: item.FileLength as number,
                        FileDate: item.FileDate as string | null,
                    }));
                    setDocuments(fileData);
                }
            } catch (err) {
                setError('Failed to fetch documents');
                console.error('Error fetching documents:', err);
            } finally {
                setIsLoading(false);
            }
        };

        void fetchDocuments();
    }, [workflowInstanceId]);

    const sortedDocuments = useMemo(() => {
        return [...documents].sort((a, b) => {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];

            if (aValue === null && bValue === null) return 0;
            if (aValue === null) return 1;
            if (bValue === null) return -1;

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                if (sortColumn === 'FileDate') {
                    // Convert string dates to timestamps for comparison
                    const aTime = new Date(aValue).getTime();
                    const bTime = new Date(bValue).getTime();
                    return sortDirection === 'ascending' ? aTime - bTime : bTime - aTime;
                }
                return sortDirection === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return sortDirection === 'ascending' ? aValue - bValue : bValue - aValue;
            }

            return 0;
        });
    }, [documents, sortColumn, sortDirection]);

    const totalPages = Math.ceil(sortedDocuments.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(currentPage * ITEMS_PER_PAGE, sortedDocuments.length);
    const paginatedDocuments = sortedDocuments.slice(startIndex, endIndex);

    const handleSort = (columnId: keyof FileData) => {
        if (columnId === sortColumn) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortColumn(columnId);
            setSortDirection('ascending');
        }
    };

    const columns: Array<TableColumnDefinition<FileData>> = [
        {
            columnId: 'FileName',
            compare: (a, b) => a.FileName.localeCompare(b.FileName),
            renderHeaderCell: () => {
                return (
                    <TableHeaderCell
                        onClick={() => { handleSort('FileName'); }}
                        sortDirection={sortColumn === 'FileName' ? sortDirection : undefined}
                    >
                        File Name
                    </TableHeaderCell>
                );
            },
            renderCell: (item) => <TableCellLayout>{item.FileName}</TableCellLayout>,
        },
        {
            columnId: 'FileLength',
            compare: (a, b) => a.FileLength - b.FileLength,
            renderHeaderCell: () => {
                return (
                    <TableHeaderCell
                        onClick={() => { handleSort('FileLength'); }}
                        sortDirection={sortColumn === 'FileLength' ? sortDirection : undefined}
                    >
                        File Size (bytes)
                    </TableHeaderCell>
                );
            },
            renderCell: (item) => <TableCellLayout>{item.FileLength}</TableCellLayout>,
        },
        {
            columnId: 'FileDate',
            compare: (a, b) => {
                if (a.FileDate === null && b.FileDate === null) return 0;
                if (a.FileDate === null) return 1;
                if (b.FileDate === null) return -1;
                return new Date(a.FileDate).getTime() - new Date(b.FileDate).getTime();
            },
            renderHeaderCell: () => {
                return (
                    <TableHeaderCell
                        onClick={() => { handleSort('FileDate'); }}
                        sortDirection={sortColumn === 'FileDate' ? sortDirection : undefined}
                    >
                        File Date
                    </TableHeaderCell>
                );
            },
            renderCell: (item) => <TableCellLayout>{formatDate(item.FileDate)}</TableCellLayout>,
        },
    ];

    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const handleFileUpload = (files: File[]) => {
        setUploadedFiles(files);
    };

    const handleSubmitUpload = () => {
        const fileRequest: IUserRequestWorkflowInstanceFiles = {
            workflowInstanceId: workflowInstanceId,
            workflowInstanceFiles: uploadedFiles
        }
        workflowHook.addInputUserRequestWorkflowInstanceFiles(fileRequest)
            .then(() => {
                console.log('Files uploaded');
            })
            .catch((error) => {
                console.error('Error uploading files:', error);
            });
        setIsUploadDialogOpen(false);
        setUploadedFiles([]);
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h2>Workflow Documents</h2>
                <div className={styles.headerButtons}>
                    <Dialog open={isUploadDialogOpen} onOpenChange={(_, data) => {setIsUploadDialogOpen(data.open)}}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button icon={<ArrowUploadRegular />} onClick={() => {setIsUploadDialogOpen(true)}}>Upload Documents</Button>
                        </DialogTrigger>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle>Upload Documents</DialogTitle>
                                <DialogContent>
                                    <WorkflowsFileUploader onFilesUploaded={handleFileUpload} />
                                </DialogContent>
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary">Cancel</Button>
                                    </DialogTrigger>
                                    <Button appearance="primary" onClick={handleSubmitUpload}>Submit</Button>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                    <Button icon={<ArrowLeftRegular />} onClick={onBack}>Back to Workflow</Button>
                </div>
            </div>
            <div className={styles.content}>
                {isLoading ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner size="large" label="Loading documents..." />
                    </div>
                ) : error ? (
                    <div>{error}</div>
                ) : (
                    <>
                        <div className={styles.tableContainer}>
                            <Table aria-label="Documents table" className={styles.table}>
                                <TableHeader>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <React.Fragment key={column.columnId}>
                                                {column.renderHeaderCell({})}
                                            </React.Fragment>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {paginatedDocuments.map((item, index) => (
                                        <TableRow key={index}>
                                            {columns.map((column) => (
                                                <TableCell key={`${index}-${column.columnId}`}>
                                                    {column.renderCell(item)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <div className={styles.paginationContainer}>
                            <Button
                                icon={<ChevronLeftRegular />}
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                            />
                            <span>
                                Showing {startIndex + 1}-{endIndex} of {sortedDocuments.length} documents | Page {currentPage} of {totalPages}
                            </span>
                            <Button
                                icon={<ChevronRightRegular />}
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewDocumentsPage;
