import { SelectTabEvent, Tab, TabList, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { useState } from "react";
import { PersonaAdmin } from "./Personas/PersonaAdmin";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { PromptAdmin } from "./Prompts/PromptAdmin";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground3,
        boxShadow: 'rgb(0 0 0 / 25%) 0 0.2rem 0.4rem -0.075rem',
        ...shorthands.flex(1),
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        backgroundColor: tokens.colorNeutralBackground4,
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    outer: {
        ...shorthands.margin('24px'),
    },
});
export const Admin = () => {
    const [selectedTab, setSelectedTab] = useState("personas");
    const styles = useStyles();
    const {userAdminSettings} = useAppSelector((state: RootState) => state.app);

    const handleTabSelect = (event: SelectTabEvent) => {
        const newValue = event.currentTarget.id;
        setSelectedTab(newValue);
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <TabList selectedValue={selectedTab} onTabSelect={handleTabSelect}>
                        {userAdminSettings.isPersonaAdmin && (
                            <Tab
                                data-testid="personasTab"
                                id="personas"
                                value="personas"
                                aria-label="Personas Tab"
                                title="Personas Tab"
                            >
                                Personas
                            </Tab>
                        )}
                        {userAdminSettings.isPromptAdmin && (
                            <Tab
                                data-testid="promptsTab"
                                id="prompts"
                                value="prompts"
                                aria-label="Prompts Tab"
                                title="Prompts Tab"
                            >
                                Prompts
                            </Tab>
                        )}
                    </TabList>
                </div>
            </div>
            {selectedTab === 'personas' && <PersonaAdmin/>}
            {selectedTab === 'prompts' && <PromptAdmin/>}
        </div>
    );
};
