import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface, DialogTrigger,
    Divider,
    Dropdown,
    makeStyles,
    Option, shorthands,
    Text
} from "@fluentui/react-components";
import { FC, useEffect, useState } from "react";
import { IUserWorkflow } from "../../libs/models/Workflows/UserWorkflow";
import { useWorkflow } from "../../libs/hooks/useWorkflow";
import { Button } from "@fluentui/react-button";

const useStyles = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
    dropdown: {
        width: '100%',
    },
    paddingBottom: {
        paddingBottom: '10px',
    },
    divider: {
        ...shorthands.margin('10px', '0'),
    },
});

interface AddWorkflowDialogProps {
    open: boolean;
    onCloseDialog: () => void;
    openAddWorkflowPage: (workflow: IUserWorkflow | undefined) => void;
}

export const AddWorkflowDialog: FC<AddWorkflowDialogProps> = ({open, onCloseDialog, openAddWorkflowPage}) => {
    const styles = useStyles();
    const workflowHook = useWorkflow();
    const [workflows, setWorkflows] = useState<IUserWorkflow[]>([]);
    const [selectedWorkflowId, setSelectedWorkflowId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (open) {
            workflowHook.getWorkflowsByUser()
                .then((workflows) => {
                    setWorkflows(workflows);
                })
                .catch((error) => {
                    console.error('Error getting workflows:', error);
                });
        }
    }, [open]);

    const onSubmit = () => {
        openAddWorkflowPage(workflows.find(x => x.workflowId === selectedWorkflowId));
        closeDialog();
    }

    const closeDialog = () => {
        onCloseDialog();
        setTimeout(() => {
            setSelectedWorkflowId(undefined);
        }, 200);
    };

    return (
        <Dialog open={open}>
            <DialogSurface className={styles.root}>
                <DialogBody>
                    <DialogContent>
                        <Dropdown className={styles.dropdown} placeholder={"Select a Workflow"} onOptionSelect={(_, data) => {
                            setSelectedWorkflowId(data.optionValue);
                        }}>
                            {workflows.map((workflow) => (
                                <Option key={workflow.workflowId} value={workflow.workflowId} text={workflow.name}>
                                    {workflow.name}
                                </Option>
                            ))}
                        </Dropdown>
                        <Divider className={styles.divider} />
                        <Text>
                            {workflows.find(x => x.workflowId === selectedWorkflowId)?.description}
                        </Text>
                    </DialogContent>
                    <DialogActions className={styles.actions}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={closeDialog}>Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="primary" onClick={onSubmit}>Create</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
