import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';
import { FeatureKeys } from '../../redux/features/app/AppState';
import { LitiumDocViewer } from '../chat/controls/LitiumDocViewer';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export const ChatView: FC = () => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { features } = useAppSelector((state: RootState) => state.app);
    const { selectedDocument } = useAppSelector((state: RootState) => state.app);

    const showDocViewer = selectedDocument.showDocument && features[FeatureKeys.DocViewer].enabled;

    return (
        <div className={classes.container}>
            <ChatList />
            {selectedId !== '' && <ChatWindow />}
            {showDocViewer && (
            <LitiumDocViewer  />
            )}
        </div>
    );
};
