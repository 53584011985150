import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTrigger, Input, Label,
    Slider, Textarea, Tooltip, makeStyles, shorthands } from "@fluentui/react-components";
import { FC, useState } from "react";
import { IChatPersona } from "../../../libs/models/ChatPersona";
import { v4 as uuidv4 } from 'uuid';
import { usePersonas } from "../../../libs/hooks/usePersonas";

const useStyles = makeStyles({
    outer: {
        ...shorthands.margin('24px'),
    },
    root: {

    },
    actions: {
        paddingTop: '10%',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        marginTop: '5px',
        marginBottom: '20px',
    },
    tagGroup: {
        marginTop: '5px',
    },
});

interface AddPersonaDialogProps {
    open: boolean;
    onCloseDialog: () => void;
    onRefresh: () => void;
}
export const AddPersonaDialog: FC<AddPersonaDialogProps> = ({ open, onCloseDialog, onRefresh }) => {
    const personaHook = usePersonas();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [systemPrompt, setSystemPrompt] = useState("");
    const [initialBotMessage, setInitialBotMessage] = useState("");
    const [sliderResponseTemperature, setSliderResponseTemperature] = useState(70);
    const styles = useStyles();

    const handleSubmit = () => {
        const responseTemperature = sliderResponseTemperature / 100;
        const newPersona: IChatPersona = {
            id: uuidv4(),
            name,
            description,
            departments: [],
            icon: "",
            systemPrompt,
            systemResponse: "Respond to the best of your ability to fulfill the request.",
            initialBotMessage,
            intentTemperature: 0.7,
            responseTemperature,
        };

        personaHook.createPersona(newPersona).then(() => {
            onCloseDialog();
            onRefresh();
        }).catch((error) => {
            console.error("Failed to create persona:", error);
        });
    };

    return (
        <div>
            <Dialog open={open}>
                <DialogSurface className={styles.root}>
                    <DialogBody>
                        <DialogContent className={styles.dialogContent}>
                            <Label>Name</Label>
                            <Input className={styles.input} value={name} onChange={(_e, data) => {
                                setName(data.value || "")
                            }}/>
                            <Label>Description</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '100px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={description}
                                onChange={(_e, data) => {
                                    setDescription(data.value || "")
                                }}/>
                            <Label>System Prompt</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '200px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={systemPrompt}
                                onChange={(_e, data) => {
                                    setSystemPrompt(data.value || "")
                                }}/>
                            <Label>Initial Bot Message</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '100px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={initialBotMessage}
                                onChange={(_e, data) => {
                                    setInitialBotMessage(data.value || "")
                                }}/>
                            <Label>Response Temperature</Label>
                            <Tooltip content={(sliderResponseTemperature / 100).toString()} relationship={"label"}>
                                <Slider
                                    value={sliderResponseTemperature}
                                    onChange={(_e, data) => {
                                        setSliderResponseTemperature(data.value);
                                    }}
                                />
                            </Tooltip>
                        </DialogContent>
                        <DialogActions className={styles.actions}>
                            <DialogTrigger>
                                <Button appearance="secondary" onClick={onCloseDialog}>Cancel</Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button appearance="primary" onClick={handleSubmit}>Create</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
};
