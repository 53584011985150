import {
    Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTrigger, Input, Label,
    Slider, Textarea, Tooltip, makeStyles, shorthands
} from "@fluentui/react-components";
import { FC, useEffect, useState } from "react";
import { usePersonas } from "../../../libs/hooks/usePersonas";
import { IChatPersona } from "../../../libs/models/ChatPersona";

const useStyles = makeStyles({
    outer: {
        ...shorthands.margin('24px'),
    },
    root: {},
    actions: {
        paddingTop: '10%',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        marginTop: '5px',
        marginBottom: '20px',
    },
    tagGroup: {
        marginTop: '5px',
    },
    dangerButton: {
        backgroundColor: '#7a0012',
        color: 'white',
    },
});

interface EditPersonaDialogProps {
    open: boolean;
    onCloseDialog: () => void;
    persona: IChatPersona | undefined;
    onRefresh: () => void;
}

export const EditPersonaDialog: FC<EditPersonaDialogProps> = ({open, onCloseDialog, persona, onRefresh}) => {
    const personaHook = usePersonas();
    const styles = useStyles();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [systemPrompt, setSystemPrompt] = useState("");
    const [initialBotMessage, setInitialBotMessage] = useState("");
    const [sliderResponseTemperature, setSliderResponseTemperature] = useState(70);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        if (persona != undefined) {
            setName(persona.name);
            setDescription(persona.description);
            setSystemPrompt(persona.systemPrompt);
            setInitialBotMessage(persona.initialBotMessage);
            setSliderResponseTemperature(persona.responseTemperature * 100);
        }
    }, [persona]);

    const handleSubmit = () => {
        if (persona == undefined) {
            console.error("Persona is undefined");
            return;
        }

        const responseTemperature = sliderResponseTemperature / 100;
        const updatedPersona: IChatPersona = {
            id: persona.id,
            name,
            description,
            departments: persona.departments,
            icon: persona.icon,
            systemPrompt,
            systemResponse: persona.systemResponse,
            initialBotMessage,
            intentTemperature: persona.intentTemperature,
            responseTemperature,
        };

        personaHook.updatePersona(updatedPersona).then(() => {
            onCloseDialog();
            onRefresh();
        }).catch((error) => {
            console.error("Failed to update persona:", error);
        });
    }

    const handleDelete = () => {
        if (persona == undefined) {
            console.error("Persona is undefined");
            return;
        }
        personaHook.deletePersona(persona.id).then(() => {
            setDeleteDialogOpen(false);
            onCloseDialog();
            onRefresh();
        }).catch((error) => {
            console.error("Failed to delete persona:", error);
        });
    };

    return (
        <div>
            <Dialog open={open}>
                <DialogSurface className={styles.root}>
                    <DialogBody>
                        <DialogContent className={styles.dialogContent}>
                            <Label>Name</Label>
                            <Input className={styles.input} value={name} onChange={(_e, data) => {
                                setName(data.value || "")
                            }}/>
                            <Label>Description</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '100px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={description}
                                onChange={(_e, data) => {
                                    setDescription(data.value || "")
                                }}/>
                            <Label>System Prompt</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '200px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={systemPrompt}
                                onChange={(_e, data) => {
                                    setSystemPrompt(data.value || "")
                                }}/>
                            <Label>Initial Bot Message</Label>
                            <Textarea
                                textarea={{
                                    style: {
                                        height: '100px'
                                    }
                                }}
                                resize={"vertical"}
                                className={styles.input}
                                value={initialBotMessage}
                                onChange={(_e, data) => {
                                    setInitialBotMessage(data.value || "")
                                }}/>
                            <Label>Response Temperature</Label>
                            <Tooltip content={(sliderResponseTemperature / 100).toString()} relationship={"label"}>
                                <Slider
                                    value={sliderResponseTemperature}
                                    onChange={(_e, data) => {
                                        setSliderResponseTemperature(data.value);
                                    }}
                                />
                            </Tooltip>
                        </DialogContent>
                        <DialogActions className={styles.actions}>
                            <DialogTrigger>
                                <Button className={styles.dangerButton} appearance="primary" onClick={() => {
                                    setDeleteDialogOpen(true)
                                }}>Delete</Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button appearance="secondary" onClick={onCloseDialog}>Cancel</Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button appearance="primary" onClick={handleSubmit}>Edit</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog open={deleteDialogOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogContent>
                            Are you sure you want to delete this persona?
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger>
                                <Button appearance="secondary" onClick={() => {
                                    setDeleteDialogOpen(false)
                                }}>Cancel</Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button className={styles.dangerButton} appearance="primary" onClick={handleDelete}>Delete</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    )
}
