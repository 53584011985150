export interface IUserWorkflowInstance {
    workflowId: string;
    workflowInstanceId: string;
    name: string;
    description: string;
    instanceName: string;
    workflowVersion: string;
    versionDate: string;
    icon: string;
    userInput: Record<string, string>;
    workflowInstanceState: WorkflowInstanceState;
    workflowInstanceCreated: string;
    workflowInstanceCompleted?: string;
    userWorkflowInstanceUsers: IUserWorkflowInstanceUser[];
}

export enum WorkflowInstanceState {
    CREATED = 0,
    READYFORPROCESSING = 1,
    PROCESSING = 2,
    REPROCESS = 3,
    ERRORED = 4,
    COMPLETE = 5,
}

export interface IUserWorkflowInstanceUser {
    userId: string;
    userName: string;
    displayName: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    email: string;
}
